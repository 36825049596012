import React from "react"
import { Button, Col, Drawer, Image, Layout, Modal, Row, Typography } from "antd"

import { POST_API, delToken, getToken, POST_CATCH } from "../../services/api"
import { Link, Navigate, Outlet } from "react-router-dom"

import { IoExitOutline, IoMenuOutline, IoPersonOutline, IoSearchOutline } from "react-icons/io5"
import { ExclamationCircleOutlined } from '@ant-design/icons';

import logo from '../../assets/img/logo.png';
import mask from '../../assets/img/mask.png';

class SiteMain extends React.Component {

    state = {
        _loading: false,
        _navigate: false,
        _menu: false,
        _scroll: false,
    }

    onMenu = () => {
        this.setState({_menu: !this.state._menu})
    }

    componentDidMount = () => {
        window.addEventListener('scroll', (val) => {
            this.setState({_scroll: (val.target.scrollingElement.scrollTop > 99)})
        })
    }

    render = () => {

        if (this.state._navigate) {
            return <Navigate to="/login" />
        }

        return (
            <Layout>
                <Layout.Content className={`site-content`}>
                    <Row className={ this.state._scroll ? "site-nav-pos" : "site-nav-pos hidden" }>
                        <Col span={24}>
                            <Row className="site-nav"  align={'middle'}>
                                <Col md={this.state._scroll ? 0 : 24} xs={0} className="site-nav-col-phone">
                                    <Typography className="site-nav-phone">(11) 2386-0460 / 2386-0462 / 3865-9086</Typography>
                                </Col>
                                <Col md={8} xs={12}>
                                    <Image src={logo} preview={false} />
                                </Col>
                                <Col md={8} xs={0}>
                                    <Typography className="site-nav-address">RUA NAZARÉ PAULISTA, 327 -<br /> VILA MADALENA - 05448-000</Typography>
                                </Col>
                                <Col md={8} xs={12}>
                                    <Row justify={"end"} align={'middle'}>
                                        <Col><Link to="painel"><Button className="btn-nav" shape="round" type="link"><IoPersonOutline size={18} /></Button></Link></Col>
                                        <Col><Button className="btn-nav" shape="round" type="link"><IoSearchOutline size={18} /></Button></Col>
                                        <Col><Button className="btn-nav btn-nav-menu" type="link">MENU</Button></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Image src={mask} preview={false} className="site-nav-comp" />
                        </Col>
                    </Row>
                    <Row className="site-container">
                        <Col span={24}>
                            <Outlet />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )
    }

}

export default SiteMain