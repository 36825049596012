import React from "react";
import { Breadcrumb, Button, Card, Col, Form, Input, Row, Spin, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken, titleWeb } from "../../services/api";


class EyeForm extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        doc: false,
    }

    back = "/painel/olho";

    routes = [
        {title: <Typography className="page-bread">Dados Sistema</Typography>},
        {title: <Link to={this.back}><Typography className="page-bread">Olhos</Typography></Link>},
        {title: <Typography className="page-bread active">{this.props.type === 'add' ? 'Adicionar' : 'Editar'}</Typography>},
    ]

    componentDidMount = () => {
        titleWeb('Olhos')
        if (this.props.type === 'add') {
            this.setState({_screen: false})
        } else if (this.props.type === 'edit') {
            this.onView()
        }
    }

    onView = async () => {
        POST_API('/eye/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/eye/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={this.back} />)
        }

        return (
            <Row>
                <Col span={18} style={{marginBottom: '18px'}}>
                    <Breadcrumb items={this.routes} />
                </Col>
                <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                </Col>
                { this.state._screen ? <Col span={24}><center><Spin /></center></Col> : (
                    <Col span={24}>
                        <Card>
                            <Form layout="vertical" className={`admin-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                                <Form.Item hidden name="ID">
                                    <Input />
                                </Form.Item>
                                <Row gutter={[16,0]}>
                                    <Col md={24} xs={24}>
                                        <Form.Item label="Nome" name="NAME" class="profile-form-item" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" style={{float: 'right'}}>Salvar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                ) }
            </Row>
        )

    }

}

export default EyeForm;