import React from "react";
import { Button, Col, Divider, Layout, Row, Typography } from 'antd';
import { Link } from "react-router-dom";

import { IoCubeOutline, IoStarOutline, IoAccessibilityOutline, IoMaleFemaleOutline, IoEyeOutline, IoAlbumsOutline, IoPeopleOutline, IoPersonOutline, IoLaptopOutline } from 'react-icons/io5';

class ComponentSidebar extends React.Component {

    state = { select: 'dashboard', paths: [] }

    componentDidMount = () => {
        
        const params = window.location.href;
        const values = params.split('/');

        values[4] = values[4] ? values[4] : values[3]
        
        this.onSelect(values[4])

    }

    onSelect = (page) => {
        if (this.props?.clickOption) this.props?.clickOption()
        this.setState({select: page})
    }


    render = () => {

        const { Content } = Layout

        return (
            <Content style={{marginTop: 15}}>
                <Row gutter={[2,2]}>
                    <Col span={24} className={`admin-sidebar-item`}>
                        <Link to={'/painel'}>
                            <Button onClick={() => this.onSelect('painel')} type="link" block className={this.state.select === 'painel' ? `admin-sidebar-item-link active` : `admin-sidebar-item-link`}>
                                <IoCubeOutline size={22}/> Dashboard 
                            </Button>
                        </Link>
                    </Col>   
                    <Col span={24} className={`admin-sidebar-item`}>
                        <Link to={'modelo'}>
                            <Button onClick={() => this.onSelect('modelo')} type="link" block className={this.state.select === 'modelo' ? `admin-sidebar-item-link active` : `admin-sidebar-item-link`}>
                                <IoPersonOutline size={22}/> Modelos
                            </Button>
                        </Link>
                    </Col>   
                    <Col span={24} className={`admin-sidebar-item`}>
                        <Link to={'trabalho'}>
                            <Button onClick={() => this.onSelect('trabalho')} type="link" block className={this.state.select === 'trabalho' ? `admin-sidebar-item-link active` : `admin-sidebar-item-link`}>
                                <IoLaptopOutline size={22}/> Trabalhos
                            </Button>
                        </Link>
                    </Col>   
                    <Col span={24} className={`admin-sidebar-item`}>
                        <Link to={'cliente'}>
                            <Button onClick={() => this.onSelect('cliente')} type="link" block className={this.state.select === 'cliente' ? `admin-sidebar-item-link active` : `admin-sidebar-item-link`}>
                                <IoPeopleOutline size={22}/> Clientes
                            </Button>
                        </Link>
                    </Col>   
                    <Col span={24} className={`sidebar-item`}>
                        <Divider style={{marginTop: 0, marginBottom: 0}} orientation="left" ><Typography style={{textTransform: 'uppercase', color: '#00000065', fontSize: 10}}>Dados Sistema</Typography></Divider>
                    </Col>
                    <Col span={24} className={`admin-sidebar-item`}>
                        <Link to={'cabelo'}>
                            <Button onClick={() => this.onSelect('cabelo')} type="link" block className={this.state.select === 'cabelo' ? `admin-sidebar-item-link active` : `admin-sidebar-item-link`}>
                                <IoStarOutline size={22}/> Cabelos
                            </Button>
                        </Link>
                    </Col> 
                    <Col span={24} className={`admin-sidebar-item`}>
                        <Link to={'pele'}>
                            <Button onClick={() => this.onSelect('pele')} type="link" block className={this.state.select === 'pele' ? `admin-sidebar-item-link active` : `admin-sidebar-item-link`}>
                                <IoAccessibilityOutline size={22}/> Peles
                            </Button>
                        </Link>
                    </Col> 
                    <Col span={24} className={`admin-sidebar-item`}>
                        <Link to={'genero'}>
                            <Button onClick={() => this.onSelect('genero')} type="link" block className={this.state.select === 'genero' ? `admin-sidebar-item-link active` : `admin-sidebar-item-link`}>
                                <IoMaleFemaleOutline size={22}/> Gêneros
                            </Button>
                        </Link>
                    </Col> 
                    <Col span={24} className={`admin-sidebar-item`}>
                        <Link to={'olho'}>
                            <Button onClick={() => this.onSelect('olho')} type="link" block className={this.state.select === 'olho' ? `admin-sidebar-item-link active` : `admin-sidebar-item-link`}>
                                <IoEyeOutline size={22}/> Olhos
                            </Button>
                        </Link>
                    </Col> 
                    <Col span={24} className={`admin-sidebar-item`}>
                        <Link to={'tipomodelo'}>
                            <Button onClick={() => this.onSelect('tipomodelo')} type="link" block className={this.state.select === 'tipomodelo' ? `admin-sidebar-item-link active` : `admin-sidebar-item-link`}>
                                <IoAlbumsOutline size={22}/> Tipos de Modelo
                            </Button>
                        </Link>
                    </Col> 
                </Row>
            </Content>
        )
    }

}

export default ComponentSidebar;