import React from "react";
import { Button, Col, Divider, Image, Layout, Row, Typography } from 'antd';
import { Link } from "react-router-dom";

import { IoCubeOutline, IoStarOutline, IoAccessibilityOutline, IoMaleFemaleOutline, IoEyeOutline, IoAlbumsOutline, IoPeopleOutline, IoPersonOutline, IoLaptopOutline } from 'react-icons/io5';

class ComponentModel extends React.Component {

    render = () => {
        return (
            <div className="model-container">
                <div className="model-content" style={{background: 'url("https://v2models.com/wp-content/uploads/galeria/DEV_9032023.08.29-16.37.08.jpeg")'}}>
                    <div className="model-filter"></div>
                    <div className="model-dados">
                        <Typography className="model-name">Nome</Typography>
                        <Typography className="model-lastname">Sobrenome</Typography>
                        <Row className="model-cont-dados">
                            <Col span={8}>
                                <Typography className="model-dados-title">ALTURA</Typography>
                                <Typography className="model-dados-data">170</Typography>
                            </Col>
                            <Col span={8}>
                                <Typography className="model-dados-title">OLHOS</Typography>
                                <Typography className="model-dados-data">CASTANHOS</Typography>
                            </Col>
                            <Col span={8}>
                                <Typography className="model-dados-title">CABELOS</Typography>
                                <Typography className="model-dados-data">CASTANHOS</Typography>
                            </Col>
                            <Col span={6}>
                                <Typography className="model-dados-title">MANEQUIM</Typography>
                                <Typography className="model-dados-data">38</Typography>
                            </Col>
                            <Col span={6}>
                                <Typography className="model-dados-title">CINTURA</Typography>
                                <Typography className="model-dados-data">39</Typography>
                            </Col>
                            <Col span={6}>
                                <Typography className="model-dados-title">QUADRIL</Typography>
                                <Typography className="model-dados-data">40</Typography>
                            </Col>
                            <Col span={6}>
                                <Typography className="model-dados-title">SAPATOS</Typography>
                                <Typography className="model-dados-data">41/42</Typography>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }

}

export default ComponentModel;