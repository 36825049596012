import React from "react"
import { Button, Col, Drawer, Image, Layout, Modal, Result, Row } from "antd"

import { POST_API, delToken, getToken, POST_CATCH } from "../services/api"
import { Navigate, Outlet } from "react-router-dom"

import SidebarComponent from '../components/Sidebar'
import { IoExitOutline, IoMenuOutline } from "react-icons/io5"
import { ExclamationCircleOutlined } from '@ant-design/icons';

import logo from '../assets/img/logo.png';

class ScreenDashboard extends React.Component {

    state = {
        _loading: false,
        _navigate: false,
        _menu: false,
        _user: null
    }

    componentDidMount = () => {
        if (getToken()) {
            POST_API('/credential/verify.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (!res.return) {
                    this.setState({_navigate: true})
                } else {
                    this.setState({_user: res.user})
                }
            }).catch(() => POST_CATCH()).finally(() => {
                this.setState({_loading: false})
            })
        } else {
            this.setState({_navigate: true})
        }
    }

    onLogOut = () => {
        var self = this
        Modal.confirm({
            title: 'Sair do sistema?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() { delToken(); self.setState({_navigate: true}) },
            onCancel() {},
        })
    }

    onMenu = () => {
        this.setState({_menu: !this.state._menu})
    }

    render = () => {

        if (this.state._navigate) {
            return <Navigate to="/login" />
        }

        if (this.state._user === null) {
            
            return(
                <Result
                    style={{height: '100vh'}}
                    status="403"
                    title="403"
                    subTitle="Você não tem permissão para acessar esssa página"
                />
              )
        }

        return (
            <Layout>
                <Layout.Content className={`admin-content`}>
                    <Row className={`admin-navbar`} align="middle" justify="space-between">
                        <Col className="menu-button"><Button onClick={this.onMenu} shape="circle" className="btn-nav" type="link"><IoMenuOutline size={22} /></Button></Col>
                        <Col><Image style={{marginBottom: 10, objectFit: 'contain'}} src={logo} preview={false} width='150px' height="50px" /></Col>
                        <Col><Button onClick={this.onLogOut} shape="circle" className="btn-nav" type="link"><IoExitOutline size={18} /></Button></Col>
                    </Row>
                    <Row>
                        <Col xs={0} md={4} className={`admin-sidebar`}>
                            <SidebarComponent />
                        </Col>
                        <Col xs={24} md={20} className="admin-main-row">
                            <Outlet />
                        </Col>
                    </Row>
                    <Drawer size="small" placement="left" open={this.state._menu} onClose={this.onMenu} width={300}>
                        <SidebarComponent clickOption={this.onMenu} />
                    </Drawer>
                </Layout.Content>
            </Layout>
        )
    }

}

export default ScreenDashboard