
import React from "react";
import { Breadcrumb, Button, Col, Divider, Image, Modal, Row, Select, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoEye, IoEyeOff, IoHeart, IoHeartDislike, IoPencil, IoReturnUpBackOutline, IoTrash } from "react-icons/io5";
import { PiSlideshow, PiSlideshowFill } from "react-icons/pi";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, POST_CATCH, getToken, titleWeb } from "../../services/api";

// COMPONENTS
import Table from '../../components/Table';

class ModelList extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _map: false,
        _map_data: [],
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: {
            current: 1,
            total: 0,
            limit: 10
        },
        table_sorter: null,
        table_select: [],
        table_filter: {
            EYE_ID: '*',
            GENDER_ID: '*',
            HAIR_ID: '*',
            SHOW_HOME: '*',
            SHOW_BANNER: '*',
            SHOW_SITE: '*',
            SKIN_ID: '*',
            TYPE_MODEL_ID: '*',
        },
        filters: {
            TYPE_MODEL_ID: [],
            GENDER_ID: [],
            SKIN_ID: [],
            EYE_ID: [],
            HAIR_ID: [],
            SHOW_HOME: [],
            SHOW_BANNER: [],
            SHOW_SITE: [],
        }
    }

    back = "/painel/modelo";

    routes = [
        {title: <Typography className="page-bread">Dados Sistema</Typography>},
        {title: <Link to={this.back} onClick={this.onChangePage}><Typography className="page-bread">Modelos</Typography></Link>},
        {title: <Typography className="page-bread active">{this.props.type === 'list' ? 'Lista' : 'Lixeira'}</Typography>},
    ]

    componentDidMount = () => {
        titleWeb('Modelos')
        this.onFilters()
        this.onRenderColumns().then(() => this.onRenderData())
    }

    onFilters = () => {
        POST_API('/model/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                console.log(res.data)
                this.setState({filters: res.data})
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH())
    }
    
    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/model/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify(this.state.table_filter), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/model/del.php', { token: getToken(), ID: ID, type: type }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    onShowSite = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/model/showsite.php', { token: getToken(), ID: ID, type: type }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    onShowHome = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/model/showhome.php', { token: getToken(), ID: ID, type: type }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    onShowBanner = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/model/showbanner.php', { token: getToken(), ID: ID, type: type }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        
        this.setState({
            table_column: [
                { title: 'Foto', dataIndex: 'AVATAR', key: 'model.AVATAR', width: 120, align: 'center', showSorterTooltip: false, render: (text, record) => (
                    <Image shape="square" src={record.CAPA ? record.CAPA : record.GALLERY[0]?.URL} />
                ) },
                { title: 'Nome', dataIndex: 'NAME', key: 'model.NAME', sorter: { compare: (a,b) => null }, showSorterTooltip: false, render: (text, record) => (
                    <>
                        <Typography style={{fontWeight: 700, fontSize: 16}}>{record.NAME}</Typography>
                        { record.NAME_ART ? <Typography style={{fontSize: 11, textTransform: 'uppercase', color: 'var(--link)'}}>Nome Art.: {record.NAME_ART}</Typography> : null }
                        { record.BIRTH ? <Typography>Data Nascimento: {record.BIRTH_FORMAT} | {record.AGE} anos</Typography> : null }
                        <Typography style={{fontSize: 11, textTransform: 'uppercase', color: '#00000075'}}>{record.TYPE_MODEL_NAME}</Typography>
                    </>
                ) },
                { title: 'Ações', key: 'action', width: 180, render: (text, record) => (
                    <>
                    { this.props.type === 'list' ? (
                        <Row gutter={[4,4]} className="table__actions">
                            <Col><Link to={record.ID}><Button className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={14} /></Button></Link></Col>
                            <Col><Button onClick={() => this.onAction(record.ID, 'del', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button></Col>
                            <Col><Divider type="vertical"/></Col>
                            <Col><Button onClick={record.SHOW_SITE === 'S' ? () => this.onShowSite(record.ID, 'N', 'Retirar do Site') : () => this.onShowSite(record.ID, 'S', 'Mostrar no Site')} className={ record.SHOW_SITE === 'S' ? "table__btn table__btn_action_1" : "table__btn table__btn_primary" } title={record.SHOW_SITE === 'S' ? 'Retirar do Site' : 'Mostrar no Site'} type="primary" shape="circle" size="small">{ record.SHOW_SITE === 'S' ? <IoEye size={14} /> : <IoEyeOff size={14} /> }</Button></Col>
                            <Col><Button onClick={record.SHOW_HOME === 'S' ? () => this.onShowHome(record.ID, 'N', 'Retirar da Home') : () => this.onShowHome(record.ID, 'S', 'Mostrar na Home')} className={ record.SHOW_HOME === 'S' ? "table__btn table__btn_action_1" : "table__btn table__btn_primary" } title={record.SHOW_HOME === 'S' ? 'Retirar da Home' : 'Mostrar na Home'} type="primary" shape="circle" size="small">{ record.SHOW_HOME === 'S' ? <IoHeart size={14} /> : <IoHeartDislike size={14} /> }</Button></Col>
                            <Col><Button onClick={record.SHOW_BANNER === 'S' ? () => this.onShowBanner(record.ID, 'N', 'Retirar do Banner') : () => this.onShowBanner(record.ID, 'S', 'Mostrar no Banner')} className={ record.SHOW_BANNER === 'S' ? "table__btn table__btn_action_1" : "table__btn table__btn_primary" } title={record.SHOW_BANNER === 'S' ? 'Retirar da Home' : 'Mostrar na Home'} type="primary" shape="circle" size="small">{ record.SHOW_BANNER === 'S' ? <PiSlideshowFill size={14} /> : <PiSlideshow size={14} /> }</Button></Col>
                        </Row>
                    ) : (
                        <Row gutter={[4,4]} className="table__actions">
                            <Col><Button onClick={() => this.onAction(record.ID, 'rec', 'Recuperar registro?')} className="table__btn table__btn_recover" title='Recuperar' type="primary" shape="circle" size='small'><IoReturnUpBackOutline size={14} /></Button></Col>
                        </Row>
                    ) }
                    </>
                )},
            ]
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onSelect = (selectedRowKeys, selectedRows) => {
        this.setState({table_select: selectedRows})
    };

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    render = () => {
        
        if (this.state._navigate) {
            return (<Navigate to={'/painel'} />)
        }

        return (
            <Row>
                <Col span={18}  style={{marginBottom: '18px'}}>
                    <Breadcrumb items={this.routes} />
                </Col>
                { this.props.type === 'list' ? (
                    <Col span={6}  style={{display: 'flex', justifyContent: 'flex-end'}}>
                        { this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'del-select', 'Excluir registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Deletar Selecionados ({this.state.table_select.length})</Button> : null }
                        <Link to={'trash'}><Button onClick={this.onChangePage} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Lixeira</Button></Link>
                        <Link to={'add'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Novo</Button></Link>
                    </Col>
                ) : (
                    <Col md={6} xs={24} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        { this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'rec-select', 'Recuperar registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Recuperar Selecionados ({this.state.table_select.length})</Button> : null }
                        <Link to={this.back}><Button onClick={this.onChangePage} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                    </Col>
                ) }
                <Col span={24}>
                    <Row className="table__row_table" gutter={[8,8]} style={{marginBottom: 10}} align={'bottom'}>
                        <Col xs={24} md={9}>
                            <Typography className="label_filter">Tipo de Modelo</Typography>
                            <Select style={{width: '100%'}} value={this.state.table_filter.TYPE_MODEL_ID} onChange={(v) => this.setState({table_filter: { ...this.state.table_filter, TYPE_MODEL_ID: v }})}>
                                <Select.Option value="*">Todos</Select.Option>
                                { this.state.filters.TYPE_MODEL_ID.map((v,i) => (
                                    <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option>
                                )) }
                            </Select>
                        </Col>
                        <Col xs={24} md={5}>
                            <Typography className="label_filter">Visível no site</Typography>
                            <Select style={{width: '100%'}} value={this.state.table_filter.SHOW_SITE} onChange={(v) => this.setState({table_filter: { ...this.state.table_filter, SHOW_SITE: v }})}>
                                <Select.Option value="*">Todos</Select.Option>
                                { this.state.filters.SHOW_SITE.map((v,i) => (
                                    <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option>
                                )) }
                            </Select>
                        </Col>
                        <Col xs={24} md={5}>
                            <Typography className="label_filter">Visível na home</Typography>
                            <Select style={{width: '100%'}} value={this.state.table_filter.SHOW_HOME} onChange={(v) => this.setState({table_filter: { ...this.state.table_filter, SHOW_HOME: v }})}>
                                <Select.Option value="*">Todos</Select.Option>
                                { this.state.filters.SHOW_HOME.map((v,i) => (
                                    <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option>
                                )) }
                            </Select>
                        </Col>
                        <Col xs={24} md={5}>
                            <Typography className="label_filter">Visível no banner</Typography>
                            <Select style={{width: '100%'}} value={this.state.table_filter.SHOW_BANNER} onChange={(v) => this.setState({table_filter: { ...this.state.table_filter, SHOW_BANNER: v }})}>
                                <Select.Option value="*">Todos</Select.Option>
                                { this.state.filters.SHOW_BANNER.map((v,i) => (
                                    <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option>
                                )) }
                            </Select>
                        </Col>
                        <Col xs={24} md={5}>
                            <Typography className="label_filter">Sexo</Typography>
                            <Select style={{width: '100%'}} value={this.state.table_filter.GENDER_ID} onChange={(v) => this.setState({table_filter: { ...this.state.table_filter, GENDER_ID: v }})}>
                                <Select.Option value="*">Todos</Select.Option>
                                { this.state.filters.GENDER_ID.map((v,i) => (
                                    <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option>
                                )) }
                            </Select>
                        </Col>
                        <Col xs={24} md={5}>
                            <Typography className="label_filter">Pele</Typography>
                            <Select style={{width: '100%'}} value={this.state.table_filter.SKIN_ID} onChange={(v) => this.setState({table_filter: { ...this.state.table_filter, SKIN_ID: v }})}>
                                <Select.Option value="*">Todos</Select.Option>
                                { this.state.filters.SKIN_ID.map((v,i) => (
                                    <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option>
                                )) }
                            </Select>
                        </Col>
                        <Col xs={24} md={5}>
                            <Typography className="label_filter">Cabelo</Typography>
                            <Select style={{width: '100%'}} value={this.state.table_filter.HAIR_ID} onChange={(v) => this.setState({table_filter: { ...this.state.table_filter, HAIR_ID: v }})}>
                                <Select.Option value="*">Todos</Select.Option>
                                { this.state.filters.HAIR_ID.map((v,i) => (
                                    <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option>
                                )) }
                            </Select>
                        </Col>
                        <Col xs={24} md={5}>
                            <Typography className="label_filter">Olhos</Typography>
                            <Select style={{width: '100%'}} value={this.state.table_filter.EYE_ID} onChange={(v) => this.setState({table_filter: { ...this.state.table_filter, EYE_ID: v }})}>
                                <Select.Option value="*">Todos</Select.Option>
                                { this.state.filters.EYE_ID.map((v,i) => (
                                    <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option>
                                )) }
                            </Select>
                        </Col>
                        <Col xs={24} md={4}>
                            <Button shape="round" block type="primary" onClick={this.onRenderData}>Filtrar</Button>
                        </Col>
                    </Row>
                    <Table
                        _columns={this.state.table_column}
                        _data={this.state.table_data}
                        _pagination={this.state.table_pagination}
                        _loading={this.state.table_load}
                        _onChangeTable={this.onTableChange}
                        _onChangePagination={this.onPaginationChange}
                        _onChangeSearch={this.onSearchChange}
                        _search={this.state.table_search}
                        _rowSelection={{onChange: this.onSelect, getCheckboxProps: () => {}}}
                    />
                </Col>
            </Row>
        )

    }

}

export default ModelList;