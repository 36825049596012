import { message } from "antd";

const CONF_URL = "https://v2models.topwebhost.com.br";

var PATH = '';

export const setPath = (value) => { PATH = value }
export const getPath = () => { return PATH }

export const URL_API = CONF_URL+'/services/php';
export const UPLOAD_API = CONF_URL+'/services/php/upload/upload.php';

export const POST_CATCH = () => {
    message.error('Não foi possível estabelecer uma conexão com o sistema')
}

export const BLOCK_FORM_ENTER = (e) => {
    if (e.key === "Enter") {
        e.preventDefault();
    }
}

export const getUPLOADAPI = () => {
    return UPLOAD_API+'?path='+getPath()+'&token='+getToken();
}

export const onPreview = (value) => {
    var url = value.response.url;
    url = String(url).replace('/home/topwebho/v2models.topwebhost.com.br', 'https://v2models.topwebhost.com.br')
    window.open(url)
}


export const titleWeb = (value) => {
    window.document.title = `V2 Models | ${value}`
}

export const setToken = (value) => {
    localStorage.setItem('TOKEN', value);
}

export const getToken = () => {
    return localStorage.getItem('TOKEN');
}

export const delToken = () => {
    localStorage.removeItem('TOKEN')
}


export const POST_API = (url, data) => {

    function createFormData () {
        const form = new FormData()
    
        Object.keys(data).forEach(key => {
            form.append(key, data[key]);
        });
      
        return form;
    };
  
    return fetch(URL_API+url, { method: "post", body: createFormData() })

}

