import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import ScreenLogin from "../pages/Login";
import ScreenDashboard from "../pages/Dashboard";
import HairList from "../pages/Hair/List";
import HairForm from "../pages/Hair/Form";
import SkinList from "../pages/Skin/List";
import SkinForm from "../pages/Skin/Form";
import EyeList from "../pages/Eye/List";
import EyeForm from "../pages/Eye/Form";
import GenderList from "../pages/Gender/List";
import GenderForm from "../pages/Gender/Form";
import TypeModelForm from "../pages/TypeModel/Form";
import TypeModelList from "../pages/TypeModel/List";
import ClientList from "../pages/Client/List";
import ClientForm from "../pages/Client/Form";
import ModelList from "../pages/Model/List";
import ModelForm from "../pages/Model/Form";
import WorkList from "../pages/Work/List";
import WorkForm from "../pages/Work/Form";
import SiteMain from "../pages/Site/Main";
import SiteHome from "../pages/Site/Home";


class AppStack extends React.Component {

    render = () => {
        return (

            <BrowserRouter>
                <Routes>
                    <Route path="" element={<SiteMain />}>
                        <Route path="" element={<SiteHome />} />
                    </Route>
                    <Route path="login" element={<ScreenLogin />} />
                    <Route path="painel" element={<ScreenDashboard />}>
                        <Route path="cabelo" element={<Outlet />}>
                            <Route path="" element={<HairList type="list" />} />
                            <Route path="trash" element={<HairList type="trash" />} />
                            <Route path="add" element={<HairForm type="add" />} />
                            <Route path=":id" element={<HairForm type="edit" />} />
                        </Route>
                        <Route path="pele" element={<Outlet />}>
                            <Route path="" element={<SkinList type="list" />} />
                            <Route path="trash" element={<SkinList type="trash" />} />
                            <Route path="add" element={<SkinForm type="add" />} />
                            <Route path=":id" element={<SkinForm type="edit" />} />
                        </Route>
                        <Route path="genero" element={<Outlet />}>
                            <Route path="" element={<GenderList type="list" />} />
                            <Route path="trash" element={<GenderList type="trash" />} />
                            <Route path="add" element={<GenderForm type="add" />} />
                            <Route path=":id" element={<GenderForm type="edit" />} />
                        </Route>
                        <Route path="olho" element={<Outlet />}>
                            <Route path="" element={<EyeList type="list" />} />
                            <Route path="trash" element={<EyeList type="trash" />} />
                            <Route path="add" element={<EyeForm type="add" />} />
                            <Route path=":id" element={<EyeForm type="edit" />} />
                        </Route>
                        <Route path="tipomodelo" element={<Outlet />}>
                            <Route path="" element={<TypeModelList type="list" />} />
                            <Route path="trash" element={<TypeModelList type="trash" />} />
                            <Route path="add" element={<TypeModelForm type="add" />} />
                            <Route path=":id" element={<TypeModelForm type="edit" />} />
                        </Route>
                        <Route path="cliente" element={<Outlet />}>
                            <Route path="" element={<ClientList type="list" />} />
                            <Route path="trash" element={<ClientList type="trash" />} />
                            <Route path="add" element={<ClientForm type="add" />} />
                            <Route path=":id" element={<ClientForm type="edit" />} />
                        </Route>
                        <Route path="modelo" element={<Outlet />}>
                            <Route path="" element={<ModelList type="list" />} />
                            <Route path="trash" element={<ModelList type="trash" />} />
                            <Route path="add" element={<ModelForm type="add" />} />
                            <Route path=":id" element={<ModelForm type="edit" />} />
                        </Route>
                        <Route path="trabalho" element={<Outlet />}>
                            <Route path="" element={<WorkList type="list" />} />
                            <Route path="trash" element={<WorkList type="trash" />} />
                            <Route path="add" element={<WorkForm type="add" />} />
                            <Route path=":id" element={<WorkForm type="edit" />} />
                        </Route>
                        <Route path="*" element={null} />
                    </Route>
                </Routes>
            </BrowserRouter>

        )
    }

}

export default AppStack;