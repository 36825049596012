import React from "react"
import { Button, Carousel, Col, Drawer, Image, Layout, Modal, Row, Typography } from "antd"

import { POST_API, delToken, getToken, POST_CATCH } from "../../services/api"
import { Navigate, Outlet } from "react-router-dom"

import { RiFacebookFill, RiInstagramFill, RiYoutubeFill } from "react-icons/ri"
import { ExclamationCircleOutlined } from '@ant-design/icons';

import about_bg from '../../assets/img/about_bg.jpg';
import become_model_bg_2 from '../../assets/img/become_model_bg_2.png';
import video from '../../assets/img/video.mp4';
import { ControlBar, Player } from "video-react"
import ComponentModel from "../../components/Model"

class SiteHome extends React.Component {

    state = {
        _loading: false,
        _navigate: false,
        _menu: false,
        _user: null,
        _data: [
            {
                TYPE_MODEL_NAME: 'Feminino',
                MODELS: [
                    {  },
                    {  },
                    {  },
                    {  },
                ]
            }
        ]
    }

    onMenu = () => {
        this.setState({_menu: !this.state._menu})
    }

    render = () => {

        if (this.state._navigate) {
            return <Navigate to="/login" />
        }

        return (
            <Row>
                <Col span={24}>
                    <div className="site-home-filter">
                        <Typography className="site-home-title">V2 MODELS</Typography>
                        <Typography className="site-home-subtitle">VOCÊ QUER SER MODELO?</Typography>
                        <Button shape="round" type="default">Clique Aqui!</Button>
                        <Row gutter={[8,8]}>
                            <Col><a href="https://www.facebook.com/V2Models/" target="_blank"><Button shape="circle" className="social"><RiFacebookFill size={20} color="#FFF"/></Button></a></Col>
                            <Col><a href="https://www.youtube.com/channel/UCQR3C8DdCpypUH5fr2PeY9w" target="_blank"><Button shape="circle" className="social"><RiYoutubeFill size={20} color="#FFF"/></Button></a></Col>
                            <Col><a href="https://www.instagram.com/agenciav2models/?hl=pt" target="_blank"><Button shape="circle" className="social"><RiInstagramFill size={20} color="#FFF"/></Button></a></Col>
                        </Row>
                    </div>
                    <Player src={video} autoPlay muted loop={true}>
                        <ControlBar disableCompletely={true} />
                    </Player>
                </Col>
                <Col span={24}>
                    <div className="site-home-filter">
                        <Row>
                            <Col md={12} xd={0}></Col>
                            <Col md={12} xd={24}>
                                <Typography className="site-home-about-title">Bem Vindo!</Typography>
                                <Typography className="site-home-about-bold">Sobre a Agência</Typography>
                                <Typography className="site-home-about">A agência de modelos V2 Models está no mercado profissional há mais de 12 anos descobrindo novos perfis de modelos para diferentes segmentos como:</Typography>
                                <Typography className="site-home-about">Publicidade em TV, Catálogos, Revistas, Desfile, Eventos, Prova de Roupa, E-commerce, Figurações, entre outros.</Typography>
                                <Typography className="site-home-about">Hoje a V2 Models é referência nacional como agência prestadora de serviços com modelos de várias faixas etárias e perfis diversos.</Typography>
                                <Typography className="site-home-about">A agência de modelos V2 Models possui profissionais preparados, capacitados e qualificados com vasta experiência tanto para atender como para direcionar seus agenciados ao mercado profissional.</Typography>
                                <Typography className="site-home-about">Trabalhamos com eficiência e dedicação à nossos Clientes e Agenciados.</Typography>
                                <Typography className="site-home-about">Venha Fazer parte do nosso casting!</Typography>
                                <Typography className="site-home-about-v2">V2 MODELS</Typography>
                            </Col>
                        </Row>
                    </div>
                    <Image src={about_bg} preview={false} />
                </Col>
                <Col span={24} style={{zIndex: 1010}}>
                    <div className="site-home-no-filter">
                        <Typography className="site-home-model">VOCÊ QUER</Typography>
                        <Typography className="site-home-model-fav">SER MODELO?</Typography>
                        <Typography className="site-home-model-sub">PREENCHA NOSSO FORMULÁRIO</Typography>
                        <Button shape="round" type="default">Quero ser modelo</Button>
                    </div>
                    <Image src={become_model_bg_2} preview={false} style={{marginTop: -80}}/>
                </Col>
                <Col span={24}>
                    <Typography className="site-model-title">Modelos</Typography>
                    { this.state._data.map((v, i) => (
                        <Row key={i} gutter={[8,8]} style={{paddingLeft: 60, paddingRight: 60}}>
                            <Col span={24}>
                                <Typography className="site-model-subtitle">{v.TYPE_MODEL_NAME}</Typography>
                            </Col>
                            { v.MODELS.map((v1, i1) => (
                                <Col md={6} sm={8} xs={24}>
                                    <ComponentModel key={i1} data={v1} />
                                </Col>
                            ))}
                        </Row>
                    )) }
                </Col>
            </Row>
        )
    }

}

export default SiteHome