
import React from "react";
import { Breadcrumb, Button, Col, Modal, Row, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoPencil, IoReturnUpBackOutline, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, POST_CATCH, getToken, titleWeb } from "../../services/api";

// COMPONENTS
import Table from '../../components/Table';

class HairList extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _map: false,
        _map_data: [],
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: {
            current: 1,
            total: 0,
            limit: 10
        },
        table_sorter: null,
        table_select: [],
        table_filter: { },
        filters: { }
    }

    back = "/painel/cabelo";

    routes = [
        {title: <Typography className="page-bread">Dados Sistema</Typography>},
        {title: <Link to={this.back} onClick={this.onChangePage}><Typography className="page-bread">Cabelos</Typography></Link>},
        {title: <Typography className="page-bread active">{this.props.type === 'list' ? 'Lista' : 'Lixeira'}</Typography>},
    ]

    componentDidMount = () => {
        titleWeb('Cabelos')
        this.onRenderColumns().then(() => this.onRenderData())
    }
    
    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/hair/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify(this.state.table_filter), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/hair/del.php', { token: getToken(), ID: ID, type: type, selected: JSON.stringify(self.state.table_select) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        if (type === 'del-select' || type === 'rec-select') self.setState({table_select: []})
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        
        this.setState({
            table_column: [
                { title: 'Cod.', dataIndex: 'key', key: 'hair.ID', width: 100, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Nome', dataIndex: 'NAME', key: 'hair.NAME', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Ações', key: 'action', width: 100, render: (text, record) => (
                    <>
                    { this.props.type === 'list' ? (
                        <Row gutter={[4,4]} className="table__actions">
                            <Col><Link to={record.ID}><Button className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={14} /></Button></Link></Col>
                            <Col><Button onClick={() => this.onAction(record.ID, 'del', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button></Col>
                        </Row>
                    ) : (
                        <Row gutter={[4,4]} className="table__actions">
                            <Col><Button onClick={() => this.onAction(record.ID, 'rec', 'Recuperar registro?')} className="table__btn table__btn_recover" title='Recuperar' type="primary" shape="circle" size='small'><IoReturnUpBackOutline size={14} /></Button></Col>
                        </Row>
                    ) }
                    </>
                )},
            ]
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onSelect = (selectedRowKeys, selectedRows) => {
        this.setState({table_select: selectedRows})
    };

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    render = () => {
        
        if (this.state._navigate) {
            return (<Navigate to={'/painel'} />)
        }

        return (
            <Row>
                <Col span={18}  style={{marginBottom: '18px'}}>
                    <Breadcrumb items={this.routes} />
                </Col>
                { this.props.type === 'list' ? (
                    <Col span={6}  style={{display: 'flex', justifyContent: 'flex-end'}}>
                        { this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'del-select', 'Excluir registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Deletar Selecionados ({this.state.table_select.length})</Button> : null }
                        <Link to={'trash'}><Button onClick={this.onChangePage} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Lixeira</Button></Link>
                        <Link to={'add'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Novo</Button></Link>
                    </Col>
                ) : (
                    <Col md={6} xs={24} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        { this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'rec-select', 'Recuperar registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Recuperar Selecionados ({this.state.table_select.length})</Button> : null }
                        <Link to={this.back}><Button onClick={this.onChangePage} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                    </Col>
                ) }
                <Col span={24}>
                    <Table
                        _columns={this.state.table_column}
                        _data={this.state.table_data}
                        _pagination={this.state.table_pagination}
                        _loading={this.state.table_load}
                        _onChangeTable={this.onTableChange}
                        _onChangePagination={this.onPaginationChange}
                        _onChangeSearch={this.onSearchChange}
                        _search={this.state.table_search}
                        _rowSelection={{onChange: this.onSelect, getCheckboxProps: () => {}}}
                    />
                </Col>
            </Row>
        )

    }

}

export default HairList;