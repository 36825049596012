import React from "react";
import { Breadcrumb, Button, Card, Col, Divider, Form, Input, Modal, Row, Select, Spin, Typography, Upload, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken, getUPLOADAPI, onPreview, setPath, titleWeb } from "../../services/api";
import { IoStar, IoStarOutline } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ReactInputMask from "react-input-mask";

class ModelForm extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        doc: false,
        fileList: [],
        filters: {
            TYPE_MODEL_ID: [],
            GENDER_ID: [],
            SKIN_ID: [],
            EYE_ID: [],
            HAIR_ID: [],
            SHOW_HOME: [],
            SHOW_SITE: [],
            STATES: [],
        },
        TYPE_MODEL_ID: null
    }

    back = "/painel/modelo";

    routes = [
        {title: <Typography className="page-bread">Dados Sistema</Typography>},
        {title: <Link to={this.back}><Typography className="page-bread">Modelos</Typography></Link>},
        {title: <Typography className="page-bread active">{this.props.type === 'add' ? 'Adicionar' : 'Editar'}</Typography>},
    ]

    componentDidMount = () => {
        this.onFilters()
        titleWeb('Modelos')
        setPath('model')
        if (this.props.type === 'add') {
            this.setState({_screen: false})
        } else if (this.props.type === 'edit') {
            this.onView()
        }
    }

    onFilters = () => {
        POST_API('/model/filters.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                console.log(res.data)
                this.setState({filters: res.data})
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH())
    }

    onView = async () => {
        POST_API('/model/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data, TYPE_MODEL_ID: res.data.TYPE_MODEL_ID})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/model/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    onDeletePic = (ID) => {
        var self = this
        Modal.confirm({
            title: 'Deletar foto?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/gallery/del.php', { token: getToken(), ID: ID }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onView()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
    }

    onSelectPic = (ID) => {
        var self = this
        Modal.confirm({
            title: 'Usar foto como capa?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/gallery/select.php', { token: getToken(), ID: ID, MODEL_ID: self.state.defaultValue.ID }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onView()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={this.back} />)
        }

        return (
            <Row>
                <Col span={18} style={{marginBottom: '18px'}}>
                    <Breadcrumb items={this.routes} />
                </Col>
                <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                </Col>
                { this.state._screen ? <Col span={24}><center><Spin /></center></Col> : (
                    <Col span={24}>
                        <Form layout="vertical" className={`admin-form`} onFinish={this.onSend} initialValues={this.state.defaultValue}>
                            <Card size="small" title="Dados do Modelo">
                                <Form.Item hidden name="ID">
                                    <Input />
                                </Form.Item>
                                <Row gutter={[16,0]}>
                                    <Col xs={24} md={4}>
                                        <Form.Item label="Tipo de Modelo" name="TYPE_MODEL_ID" class="profile-form-item" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                            <Select allowClear value={this.state.TYPE_MODEL_ID} onChange={(v) => this.setState({ TYPE_MODEL_ID: v })}>
                                                { this.state.filters.TYPE_MODEL_ID.map((v,i) => (
                                                    <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option>
                                                )) }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    { this.state.TYPE_MODEL_ID > 0 ? (
                                        <>
                                            <Col md={9} xs={24}>
                                                <Form.Item label="Nome" name="NAME" class="profile-form-item" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col md={7} xs={24}>
                                                <Form.Item label="Nome Artístico" name="NAME_ART" class="profile-form-item">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col md={4} xs={24}>
                                                <Form.Item label="Data Nascimento" name="BIRTH" class="profile-form-item">
                                                    <Input type="date" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Sexo" name="GENDER_ID" class="profile-form-item" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Select allowClear>
                                                        { this.state.filters.GENDER_ID.map((v,i) => (
                                                            <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option>
                                                        )) }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Pele" name="SKIN_ID" class="profile-form-item" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Select allowClear>
                                                        { this.state.filters.SKIN_ID.map((v,i) => (
                                                            <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option>
                                                        )) }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Cabelo" name="HAIR_ID" class="profile-form-item" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Select allowClear>
                                                        { this.state.filters.HAIR_ID.map((v,i) => (
                                                            <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option>
                                                        )) }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={6}>
                                                <Form.Item label="Olho" name="EYE_ID" class="profile-form-item" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Select allowClear>
                                                        { this.state.filters.EYE_ID.map((v,i) => (
                                                            <Select.Option key={i} value={v.ID}>{v.NAME}</Select.Option>
                                                        )) }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={3}>
                                                <Form.Item label="Altura" name="HEIGHT" class="profile-form-item">
                                                    <Input type="number" addonAfter="cm" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={3}>
                                                <Form.Item label="Peso" name="WEIGHT" class="profile-form-item">
                                                    <Input type="number" addonAfter="kg" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={3}>
                                                <Form.Item label="Cintura" name="WAIST" class="profile-form-item">
                                                    <Input type="number" addonAfter="cm" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={3}>
                                                <Form.Item label="Quadril" name="HIP" class="profile-form-item">
                                                    <Input type="number" addonAfter="cm" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={3}>
                                                <Form.Item label="Busto" name="BUST" class="profile-form-item">
                                                    <Input type="number" addonAfter="cm" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={4}>
                                                <Form.Item label="Sapato" name="SHOE" class="profile-form-item">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={5}>
                                                <Form.Item label="Manequim" name="DUMMY" class="profile-form-item">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col md={4} xs={8}>
                                                <Form.Item name="CEP" label="CEP" className="profile-form-item">
                                                    <ReactInputMask mask={'99999-999'} className="ant-input css-dev-only-do-not-override-19gw05y css-19gw05y" />
                                                </Form.Item>
                                            </Col>
                                            <Col md={16} xs={24}>
                                                <Form.Item name="STREET" label="Logradouro" className="profile-form-item">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col md={4} xs={8}>
                                                <Form.Item name="NUMBER" label="Número" className="profile-form-item">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col md={10} xs={16}>
                                                <Form.Item name="DISTRICT" label="Bairro" className="profile-form-item">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col md={10} xs={18}>
                                                <Form.Item name="CITY" label="Cidade" className="profile-form-item">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col md={4} xs={6}>
                                                <Form.Item name="STATE" label="UF" className="profile-form-item">
                                                    <Select>
                                                        { this.state.filters.STATES.map((v, i) => (
                                                            <Select.Option value={v} key={i}>{v}</Select.Option>
                                                        )) }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </>
                                    ) : null }
                                </Row>
                            </Card>
                            { this.state.TYPE_MODEL_ID > 0 ? (
                                <Card size="small" title="Galeria de fotos" style={{marginTop: 10}}>
                                    { this.state.defaultValue?.GALLERY.length > 0 ? <Divider style={{marginTop: 5, marginBottom: 10}} orientation="left"><Typography style={{fontSize: 9, color: '#00000067', textTransform: 'uppercase'}}>Fotos cadastradas</Typography></Divider> : null }
                                    { this.state.defaultValue?.GALLERY.length > 0 ?
                                        <span class="ant-upload-wrapper css-dev-only-do-not-override-19gw05y css-19gw05y ant-upload-picture-card-wrapper">
                                            <div class="ant-upload-list ant-upload-list-picture-card">
                                            { this.state.defaultValue?.GALLERY.map((v, i) => (
                                                    <div class="ant-upload-list-item-container">
                                                        <div class={`ant-upload-list-item ant-upload-list-item-done ${v.FAVORITE === 'S' ? 'pic-favorite' : ''}`}>
                                                            <a class="ant-upload-list-item-thumbnail" href={v.URL} target="_blank" rel="noopener noreferrer">
                                                                <img alt={v.URL} src={v.URL} class="ant-upload-list-item-image"/>
                                                            </a>
                                                            <span class="ant-upload-list-item-actions">
                                                                <a href={v.URL} target="_blank" rel="noopener noreferrer">
                                                                    <span role="img" aria-label="eye" class="anticon anticon-eye"><svg viewBox="64 64 896 896" focusable="false" data-icon="eye" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path></svg></span>
                                                                </a>
                                                                <button onClick={() => this.onDeletePic(v.ID)} title="Remover foto" type="button" class="ant-btn css-dev-only-do-not-override-19gw05y css-19gw05y ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-action">
                                                                    <span class="ant-btn-icon">
                                                                        <span role="img" aria-label="delete" tabindex="-1" class="anticon anticon-delete">
                                                                            <svg viewBox="64 64 896 896" focusable="false" data-icon="delete" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path></svg>
                                                                        </span>
                                                                    </span>
                                                                </button>
                                                                <button onClick={() => this.onSelectPic(v.ID)} title="Escolher como capa" type="button" class="ant-btn css-dev-only-do-not-override-19gw05y css-19gw05y ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-action">
                                                                    <span class="ant-btn-icon">
                                                                        <span role="img" aria-label="delete" tabindex="-1" class="anticon anticon-delete">
                                                                            {v.FAVORITE === 'S' ? <IoStar color="rgb(3, 196, 3)" /> : <IoStarOutline /> }
                                                                        </span>
                                                                    </span>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                            )) }
                                            </div>
                                        </span>
                                    : null }
                                    <Divider style={{marginTop: 5, marginBottom: 10}} orientation="left"><Typography style={{fontSize: 9, color: '#00000067', textTransform: 'uppercase'}}>Adicionar fotos</Typography></Divider>
                                    <Form.Item name="AVATAR" class="profile-form-item">
                                        <Upload
                                            accept="image/png,image/jpg,image/jpeg"
                                            action={getUPLOADAPI}
                                            listType="picture-card"
                                            onPreview={onPreview}
                                        >
                                            <div>
                                                <div style={{ marginTop: 8 }}>Selecionar</div>
                                            </div>
                                        </Upload>
                                    </Form.Item>
                                </Card>
                            ) : null}
                            { this.state.TYPE_MODEL_ID > 0 ? <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" style={{float: 'right', marginTop: 10}}>Salvar</Button> : null }
                        </Form>
                    </Col>
                ) }
            </Row>
        )

    }

}

export default ModelForm;