import { Button, Card, Form, Image, Input, Layout, Row, message } from "antd";
import React from "react";

import logo from '../assets/img/logo.png'
import { POST_API, POST_CATCH, setToken } from "../services/api";
import { Navigate } from "react-router-dom";

class ScreenLogin extends React.Component {

    state = { load: false, _navigate: false }

    onSend = async (values) => {
        
        this.setState({load: true})
        await POST_API('/credential/login.php', {token: 'X', master: JSON.stringify(values)}).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                setToken(res.token)
                this.setState({_navigate: true})
            } else {
                message.warning(res.msg)
            }
        }).catch(() => { POST_CATCH() }).finally(() => {
            this.setState({load: false})
        })

    }

    render = () => {

        if (this.state._navigate) {
            return <Navigate to="/painel" />
        }

        return (
            <Layout.Content style={{overflow: 'hidden'}}>
                <Row gutter={[8,8]} align={'middle'} justify={'center'} style={{backgroundColor: '#000', height: '100vh', flexDirection: 'column'}}>
                    <Image src={logo} preview={false} />
                    <Card size="small" style={{paddingTop: 10}}>
                        <Form layout="vertical" onFinish={this.onSend}>
                            <Form.Item name="LOGIN" rules={[{required: true, message: 'Campo Obrigatório'}]}>
                                <Input placeholder="Login" />
                            </Form.Item>
                            <Form.Item name="PASSWORD" rules={[{required: true, message: 'Campo Obrigatório'}]}>
                                <Input.Password placeholder="Senha" />
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType="submit" type="primary" block shape="round" className="btn-primary">Entrar</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Row>
            </Layout.Content>
        )

    }

};

export default ScreenLogin;